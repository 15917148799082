import 'init/1-polyfills';
import 'init/3-prelude';
import 'init/4-i18next';

/*
import { inspect } from '@xstate/inspect';
inspect({
    // options
    // url: 'https://stately.ai/viz?inspect', // (default)
    iframe: false, // open in new window
});
*/

window.alpha = true;

async function main() {
    const { renderApp } = await import('./App');
    renderApp();
}

main();
