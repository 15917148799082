import { useCallback } from 'react';
import { MenubarItem } from 'src/components/ui';
import type { VariantProps } from 'src/lib/utils';
import { cn, cva } from 'src/lib/utils';

export const channelVariants = cva('rounded-none', {
    variants: {
        bg: {
            transparent: 'bg-secondary-foreground dark:bg-secondary focus:bg-secondary/80 dark:focus:bg-secondary/80',
            blue: 'bg-sky-500 focus:bg-blue-600 dark:focus:bg-blue-400',
            green: 'bg-emerald-500 focus:bg-emerald-600 dark:focus:bg-emerald-400',
            red: 'bg-rose-500 focus:bg-rose-600 dark:focus:bg-rose-400',
            purple: 'bg-violet-500 focus:bg-violet-600 dark:focus:bg-violet-400',
            orange: 'bg-orange-500 focus:bg-orange-600 dark:focus:bg-orange-400',
            // this obviously isn't brown, but I think it'd require a migration if anybody was actually using any brown channels
            brown: 'bg-fuchsia-500 focus:bg-fuchsia-600 dark:focus:bg-fuchsia-400',
            yellow: 'bg-yellow-500 focus:bg-yellow-600 dark:focus:bg-yellow-400',
        },
        fill: {
            transparent: 'text-transparent',
            blue: 'fill-sky-500 text-sky-500',
            green: 'fill-emerald-500 text-emerald-500',
            red: 'fill-rose-500 text-rose-500',
            purple: 'fill-violet-500 text-violet-500',
            orange: 'fill-orange-500 text-orange-500',
            brown: 'fill-fuchsia-500 text-fuchsia-500',
            yellow: 'fill-yellow-500 text-yellow-500',
        },
    },
});

export const channelColors: VariantProps<typeof channelVariants>['bg'][] = [
    'orange',
    'yellow',
    'green',
    'blue',
    'purple',
    'brown',
    'red',
];

export const externalAppLinkingChannelId = 'red';
export const externalAppLinkingLabel = 'External';

export const isChannelColor = (
    color: string | null | undefined,
): color is VariantProps<typeof channelVariants>['bg'] =>
    color ? channelColors.some((channel) => channel === color) : false;

interface ChannelPickerProps {
    onChannelChange: (channelId: string | null) => void;
    onResetChannelData: () => void;
    activeChannel: string | null;
}

export const ChannelPickerMenu = ({ onChannelChange, activeChannel, onResetChannelData }: ChannelPickerProps) => {
    const onSelectColor = useCallback(
        (channelId: string | null | undefined) => {
            onChannelChange?.(channelId ?? null);
        },
        [onChannelChange],
    );

    return (
        <>
            <MenubarItem
                key={'null'}
                className={cn(channelVariants({ bg: 'transparent' }), 'place-content-center')}
                onClick={() => onSelectColor(null)}
            >
                Unlinked
            </MenubarItem>
            {activeChannel && (
                <MenubarItem
                    key={'reset'}
                    className={cn(channelVariants({ bg: 'transparent' }), 'place-content-center')}
                    onClick={() => onResetChannelData?.()}
                >
                    Clear Channel Data
                </MenubarItem>
            )}
            {channelColors.map((color) => (
                <MenubarItem
                    key={color}
                    className={cn(
                        channelVariants({ bg: color }),
                        (!APP_ELECTRON || externalAppLinkingChannelId !== color) &&
                            'text-transparent focus:text-transparent',
                    )}
                    onClick={() => onSelectColor(color)}
                >
                    {APP_ELECTRON && externalAppLinkingChannelId === color ? externalAppLinkingLabel : color}
                </MenubarItem>
            ))}
        </>
    );
};
