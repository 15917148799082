import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import type { Layout } from 'src/contracts/workspace';
import { getWidgetDisplayName } from 'src/features/widget/model/widget';
import type { RootState } from 'src/store';
import type { AddWidgetToDashboardAction } from 'src/store/actions/container';
import { addContainer } from 'src/store/actions/container';
import { type AddNewWidgetToContainerAction, addNewWidgetToContainer, addTab } from 'src/store/actions/tab';
import { getWidgetFromCreationInfo } from 'src/store/sagas/widgets/construction/create-widget';
import { getMaximizedContainerId } from 'src/store/selectors/container';
import type { ContainerViewModel, SagaContext, WidgetTabViewModel } from 'src/store/types';
import { createContainerLayoutViewModel } from 'src/store/types/workspaceViewModels';
import type { StaticBindings } from 'src/types/bindings';
import { v4 as uuid } from 'uuid';

const layouts = {
    'alpha-lens': {
        h: 0.8,
        w: 0.33,
        x: 0.33,
        y: 0.1,
    },
    alphabot: {
        h: 0.8,
        w: 0.33,
        x: 0.33,
        y: 0.1,
    },
    default: {
        h: 0.2,
        w: 0.6,
        x: 0.2,
        y: 0.4,
    },
    scanner: {
        h: 0.7,
        w: 0.6,
        x: 0.2,
        y: 0.05,
    },
    screener: {
        h: 0.7,
        w: 0.6,
        x: 0.2,
        y: 0.05,
    },
    searchalpha: {
        h: 0.5,
        w: 0.6,
        x: 0.2,
        y: 0.05,
    },
};

export function* addWidgetToDashboardSaga(action: AddWidgetToDashboardAction) {
    const currentlyMaximizedContainer: string | undefined = yield select(getMaximizedContainerId);

    if (currentlyMaximizedContainer) {
        yield put(addNewWidgetToContainer(action.widget, currentlyMaximizedContainer));
        return;
    }

    let layoutToUse = layouts['default'];

    const widgetCreationInfo = action.widget;

    const ctx: SagaContext['container'] = yield getContext('container');
    const t: Awaited<ReturnType<StaticBindings['TProvider']>> = yield call(() => ctx.get('TProvider')());

    const initialWidget = yield* getWidgetFromCreationInfo(action.widget);

    const widgetDisplayName = getWidgetDisplayName(t, initialWidget);

    const initialWidgetTab = {
        id: uuid(),
        name: widgetDisplayName,
        widget: initialWidget,
    };

    switch (widgetCreationInfo.creationType) {
        case 'from-widget-default': {
            layoutToUse = layouts[widgetCreationInfo.widgetType as keyof typeof layouts] || layouts['default'];

            break;
        }
        case 'new-alpha-lens': {
            layoutToUse = layouts['alpha-lens'];
            break;
        }
        case 'new-searchalpha': {
            layoutToUse = layouts['searchalpha'];
            break;
        }

        case 'cloned': {
            const clonedLayout: Layout = yield select(
                (store: RootState) => store.layout.byContainerId[widgetCreationInfo.baseWidgetContainerId],
            );
            const { x, y, ...rest } = clonedLayout;
            layoutToUse = {
                x: x + 0.01,
                y: y + 0.025,
                ...rest,
            };

            break;
        }
    }

    const containerViewModel: ContainerViewModel = {
        id: uuid(),
        activeTabId: initialWidgetTab.id,
        tabIds: [initialWidgetTab.id],
    };

    const layoutViewModel = createContainerLayoutViewModel(layoutToUse, containerViewModel.id);

    const initialTabViewModel: WidgetTabViewModel = {
        ...initialWidgetTab,
        isActive: containerViewModel.activeTabId === initialWidgetTab.id,
        containerId: containerViewModel.id,
        channelId: initialWidgetTab.widget.channelId || null,
    };

    yield put(addContainer(containerViewModel, initialTabViewModel, layoutViewModel));
}

export function* createWidgetTabSaga(action: AddNewWidgetToContainerAction) {
    const { tab, containerId } = action;

    const widget = yield* getWidgetFromCreationInfo(tab);

    const ctx: SagaContext['container'] = yield getContext('container');
    const t: Awaited<ReturnType<StaticBindings['TProvider']>> = yield call(() => ctx.get('TProvider')());

    const widgetDisplayName = getWidgetDisplayName(t, widget);

    const tabViewModel: WidgetTabViewModel = {
        id: uuid(),
        name: widgetDisplayName,
        containerId,
        isActive: true,
        channelId: widget.channelId || null,
        widget,
    };

    yield put(addTab(tabViewModel, containerId));
}

export function* widgetConstructionSagas() {
    yield all([
        takeLatest('addWidgetToDashboard', addWidgetToDashboardSaga),
        takeLatest('addNewWidgetToContainer', createWidgetTabSaga),
    ]);
}
