import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MenubarPrimitive from '@radix-ui/react-menubar';
import { noop } from 'es-toolkit';
import * as React from 'react';
import { cn } from 'src/lib/utils';

const MenubarMenu = MenubarPrimitive.Menu;

const MenubarGroup = MenubarPrimitive.Group;

const MenubarPortal = MenubarPrimitive.Portal;

const MenubarSub = MenubarPrimitive.Sub;

const MenubarRadioGroup = MenubarPrimitive.RadioGroup;

const Menubar = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Root>
>(({ className, ...props }, ref) => (
    <MenubarPrimitive.Root
        ref={ref}
        className={cn('bg-background flex h-10 items-center space-x-1 rounded-md border p-1', className)}
        {...props}
    />
));
Menubar.displayName = MenubarPrimitive.Root.displayName;

const MenubarTrigger = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Trigger>
>(({ className, ...props }, ref) => (
    <MenubarPrimitive.Trigger
        ref={ref}
        className={cn(
            'focus:bg-accent focus:text-accent-foreground data-[state=open]:bg-accent data-[state=open]:text-accent-foreground flex select-none items-center rounded-sm px-3 py-1.5 text-sm font-medium outline-none',
            className,
        )}
        {...props}
    />
));
MenubarTrigger.displayName = MenubarPrimitive.Trigger.displayName;

const MenubarSubTrigger = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.SubTrigger>,
    React.ComponentPropsWithoutRef<typeof MenubarPrimitive.SubTrigger> & {
        inset?: boolean;
    }
>(({ className, inset, children, ...props }, ref) => (
    <MenubarPrimitive.SubTrigger
        ref={ref}
        className={cn(
            'focus:bg-accent focus:text-accent-foreground data-[state=open]:bg-accent data-[state=open]:text-accent-foreground relative flex select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none',
            inset && 'pl-8',
            className,
        )}
        {...props}
    >
        {children}
        <FontAwesomeIcon icon={faChevronRight} className="ml-auto" />
    </MenubarPrimitive.SubTrigger>
));
MenubarSubTrigger.displayName = MenubarPrimitive.SubTrigger.displayName;

const MenubarSubContent = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.SubContent>,
    React.ComponentPropsWithoutRef<typeof MenubarPrimitive.SubContent>
>(({ className, ...props }, ref) => (
    <MenubarPrimitive.SubContent
        ref={ref}
        className={cn(
            'dark:bg-gray-925 text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[8rem] overflow-hidden rounded-md border bg-gray-100 p-1',
            className,
        )}
        {...props}
    />
));
MenubarSubContent.displayName = MenubarPrimitive.SubContent.displayName;

const MenubarContent = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Content> & { container?: HTMLElement | null }
>(({ className, align = 'start', alignOffset = -4, sideOffset = 8, container, ...props }, ref) => {
    return (
        <MenubarPrimitive.Portal container={container}>
            <MenubarPrimitive.Content
                ref={ref}
                align={align}
                alignOffset={alignOffset}
                sideOffset={sideOffset}
                className={cn(
                    'dark:bg-gray-925 text-popover-foreground data-[state=open]:animate-in data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[12rem] overflow-hidden rounded-md border bg-gray-100 p-1 shadow-md',
                    className,
                )}
                {...props}
            />
        </MenubarPrimitive.Portal>
    );
});
MenubarContent.displayName = MenubarPrimitive.Content.displayName;

const MenubarItem = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Item> & {
        inset?: boolean;
    }
>(({ className, inset, children, ...props }, ref) => (
    <MenubarPrimitive.Item
        ref={ref}
        className={cn(
            'focus:bg-accent focus:text-accent-foreground relative flex select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
            inset && 'pl-8',
            className,
        )}
        {...props}
    >
        {children}
    </MenubarPrimitive.Item>
));
MenubarItem.displayName = MenubarPrimitive.Item.displayName;

export const MenubarItemIcon = ({ children }: { children: React.ReactNode }) => {
    return <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">{children}</span>;
};

const MenubarCheckboxItem = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.CheckboxItem>,
    React.ComponentPropsWithoutRef<typeof MenubarPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
    <MenubarPrimitive.CheckboxItem
        ref={ref}
        className={cn(
            'focus:bg-accent focus:text-accent-foreground relative flex select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
            className,
        )}
        checked={checked}
        {...props}
    >
        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            <MenubarPrimitive.ItemIndicator>
                <FontAwesomeIcon icon={faCheck} fixedWidth />
            </MenubarPrimitive.ItemIndicator>
        </span>
        {children}
    </MenubarPrimitive.CheckboxItem>
));
MenubarCheckboxItem.displayName = MenubarPrimitive.CheckboxItem.displayName;

const MenubarRadioItem = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.RadioItem>,
    React.ComponentPropsWithoutRef<typeof MenubarPrimitive.RadioItem>
>(({ className, children, ...props }, ref) => (
    <MenubarPrimitive.RadioItem
        ref={ref}
        className={cn(
            'focus:bg-accent focus:text-accent-foreground relative flex select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
            className,
        )}
        {...props}
    >
        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            <MenubarPrimitive.ItemIndicator>
                <FontAwesomeIcon icon={faCircle} fixedWidth size="xs" />
            </MenubarPrimitive.ItemIndicator>
        </span>
        {children}
    </MenubarPrimitive.RadioItem>
));
MenubarRadioItem.displayName = MenubarPrimitive.RadioItem.displayName;

const MenubarLabel = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Label>,
    React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Label> & {
        inset?: boolean;
    }
>(({ className, inset, ...props }, ref) => (
    <MenubarPrimitive.Label
        ref={ref}
        className={cn('px-2 py-1.5 text-sm font-semibold', inset && 'pl-8', className)}
        {...props}
    />
));
MenubarLabel.displayName = MenubarPrimitive.Label.displayName;

const MenubarSeparator = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Separator>
>(({ className, ...props }, ref) => (
    <MenubarPrimitive.Separator ref={ref} className={cn('bg-muted -mx-1 my-1 h-px', className)} {...props} />
));
MenubarSeparator.displayName = MenubarPrimitive.Separator.displayName;

const MenubarShortcut = ({ className, ...props }: React.HTMLAttributes<HTMLSpanElement>) => {
    return <span className={cn('text-muted-foreground ml-auto text-xs tracking-widest', className)} {...props} />;
};
MenubarShortcut.displayname = 'MenubarShortcut';

/**
 * Some extra components to handle situations when a menu item may have more than one click target
 *
 * For example, we often have a "label" that is the primary click target, but also a "delete" icon
 * That may open a confirmation dialog.
 *
 * With the "base" menuBar components this delete icon would be a child of the menuBarItem and the
 * event would bubble up to the menuBarItem, closing the menu, and triggering the onSelect event handler
 * before the action on our delete button could offer the user a chance to confirm.
 *
 * By using these components, we can place the primary action label and extra actions as siblings
 * their events will bubble up to the menuBarItem parent, and radix can close the menu, but each
 * configured handler in our code will not bubble through the other, since all actions will be siblings
 */

/**
 * This is a wrapper around the MenubarItem that simply sets padding to 0 to allow our other
 * Components to take over all space for click targets
 *
 * inset is not supported on this wrapper, if you need to use inset,
 * @see MenubarMultiTargetLabel and place inset there.
 */
const MenubarMultiTargetItem = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Item>
>(({ className, children, ...props }, ref) => (
    <MenubarItem ref={ref} className={cn('p-0', className)} role="" {...props}>
        {children}
    </MenubarItem>
));
MenubarMultiTargetItem.displayName = 'MenubarMultiTargetItem';

/**
 * This Component should always be used as a child of MenubarMultiTargetItem
 *
 * inset here works the same as MenubarItem, and allows for indenting the label
 * so you can use MenubarIcon within.  Same behavior/appearance as MenubarItem
 */
interface MenubarMultiTargetLabelProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    onSelect?: () => void;
    inset?: boolean;
}
const MenubarMultiTargetLabel = ({
    children,
    className,
    onSelect = noop,
    inset,
    ...props
}: MenubarMultiTargetLabelProps) => (
    <div
        role="menuitem"
        onClick={onSelect}
        className={cn('flex-grow px-2 py-1.5', inset && 'pl-8', className)}
        {...props}
    >
        {children}
    </div>
);

/**
 * This Component should always be used as a child of MenubarMultiTargetItem
 *
 * This Component is a click target that is intended to be rendered to the right of
 * MenubarMultiTargetLabel. But can be placed anywhere within a MenubarMultiTargetItem
 *
 * By default it has padding on the right same as MenubarItem, but you can override it by
 * passing in your own padding classes, useful when more then one icon is in use within a single
 * MenubarMultiTargetItem
 */
interface MenubarMultiTargetIconProps extends React.HTMLAttributes<HTMLElement> {
    children: React.ReactNode;
    onSelect?: () => void;
}
const MenubarMultiTargetIcon = ({ children, className, onSelect = noop, ...props }: MenubarMultiTargetIconProps) => (
    <div role="menuitem" onClick={onSelect} className={cn('pr-2', className)} {...props}>
        {children}
    </div>
);

export {
    Menubar,
    MenubarMenu,
    MenubarTrigger,
    MenubarContent,
    MenubarItem,
    MenubarSeparator,
    MenubarLabel,
    MenubarCheckboxItem,
    MenubarRadioGroup,
    MenubarRadioItem,
    MenubarPortal,
    MenubarSubContent,
    MenubarSubTrigger,
    MenubarGroup,
    MenubarSub,
    MenubarShortcut,
    MenubarMultiTargetItem,
    MenubarMultiTargetLabel,
    MenubarMultiTargetIcon,
};
