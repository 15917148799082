import { Duration } from '@js-joda/core';
import { PeriodType } from '@thinkalpha/language-services';
import type { Universe } from '@thinkalpha/platform-ws-client/contracts/universe.js';
import { type WorkspaceConfig } from '@thinkalpha/platform-ws-client/contracts/workspace.js';
import { getContext, call } from 'redux-saga/effects';
import type {
    AlphaLensWidgetModel,
    TimeSeriesWidgetModel,
    AlphaLensContent,
    Widget,
    NewsWidgetModel,
    EventsWidgetModel,
    FilingsWidgetModel,
} from 'src/contracts/workspace';
import {
    PRIMARY_SERIES_ID,
    VOLUME_SERIES_ID,
    timeSeriesAxisNames,
    timeSeriesPaneNames,
    defaultBarVolumeFormula,
} from 'src/features/chart';
import { WatchListWidgetModelImpl } from 'src/models/WatchListWidgetModel/impl';
import { getDefaultUniverseQuery } from 'src/queries/universes';
import { getAlphaLensDefaultWidgetQuery } from 'src/queries/userPreferences';
import { getWorkspaceConfigQuery } from 'src/queries/workspace';
import type { StrategyWidgetViewModel, SagaContext, WatchlistWidgetViewModel } from 'src/store/types';

export type WidgetDefaultGenerator<T extends Widget> = Generator<any, T, any>;

export function* getDefaultAlphaLensWidget(defaultSymbol?: string): WidgetDefaultGenerator<AlphaLensWidgetModel> {
    let symbol = defaultSymbol;

    if (!symbol) {
        const container: NonNullable<SagaContext['container']> = yield getContext('container');
        const queryClient = container.get('QueryClient');
        const baseWidget: Pick<AlphaLensContent, 'symbol' | 'pages'> = yield call(() =>
            queryClient.fetchUserQuery(getAlphaLensDefaultWidgetQuery()),
        );
        symbol = baseWidget.symbol;
    }

    const widget: AlphaLensWidgetModel = {
        type: 'alpha-lens',
        channelId: null,
        keyboardCommands: [],
        header: undefined,
        pages: null,
        symbol: symbol,
    };

    return widget;
}

export function* getDefaultTimeSeriesWidget(defaultSymbol?: string): WidgetDefaultGenerator<TimeSeriesWidgetModel> {
    const widget: TimeSeriesWidgetModel = {
        type: 'time-series',
        barLength: { value: 1, period: PeriodType.day },
        channelId: '',
        interval: {
            start: Duration.ofDays(365),
            end: null,
        },
        session: 'extended',
        plotBandColors: {
            closed: 'rgba(100,100,100,.4)',
            premarket: 'rgba(100,0,0,.4)',
            postmarket: 'rgba(0,0,100,.4)',
            market: '',
        },
        symbol: defaultSymbol ?? 'SPY',
        panes: [
            {
                id: timeSeriesPaneNames[0],
                symbol: null,
                height: 80,
                axes: [
                    {
                        id: timeSeriesAxisNames[0],
                        series: [
                            {
                                id: PRIMARY_SERIES_ID,
                                symbol: undefined,
                                type: 'candle',
                                hidden: false,
                                dataSource: {},
                            },
                        ],
                    },
                ],
            },
            {
                id: timeSeriesPaneNames[1],
                symbol: null,
                height: 20,
                axes: [
                    {
                        id: timeSeriesAxisNames[0],
                        series: [
                            {
                                id: VOLUME_SERIES_ID,
                                symbol: undefined,
                                type: 'column',
                                hidden: false,
                                // TODO AMP: Temporary until formulas are implemented, PLAT-5405
                                dataSource: { value: defaultBarVolumeFormula },
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return widget;
}

export function* getDefaultStrategyWidget(
    incomingIdeas: StrategyWidgetViewModel['ideas'],
    filter?: string,
): WidgetDefaultGenerator<StrategyWidgetViewModel> {
    const container: NonNullable<SagaContext['container']> = yield getContext('container');
    const queryClient = container.get('QueryClient');
    const defaultUniverse: Universe | undefined = yield call(() =>
        queryClient.fetchUserQuery(getDefaultUniverseQuery()),
    );

    const widget: StrategyWidgetViewModel = {
        type: 'results',
        channelId: null,
        ideas: incomingIdeas,
        universeId: defaultUniverse?.id ?? null,
        columnTemplate: null,
        filter: filter || null,
        scrolling: false,
    };

    return widget;
}

export function* getDefaultWatchlistWidget(): WidgetDefaultGenerator<WatchlistWidgetViewModel> {
    const container: NonNullable<SagaContext['container']> = yield getContext('container');
    const queryClient = container.get('QueryClient');
    const defaultWorkspaceConfig: WorkspaceConfig = yield call(() =>
        queryClient.fetchUserQuery(getWorkspaceConfigQuery()),
    );

    const widget: WatchlistWidgetViewModel = {
        type: 'watchlist',
        channelId: null,
        idea: {
            name: 'Unnamed Watchlist',
            description: 'Watchlist',
            plan: WatchListWidgetModelImpl.createEmptyWatchlistPlan().toContract(),
            defaultUniverseId: defaultWorkspaceConfig.defaultWatchlistUniverseId,
            defaultColumnTemplateId: null,
            isTemplate: false,
        },
        universeId: defaultWorkspaceConfig.defaultWatchlistUniverseId,
        columnTemplate: null,
        filter: null,
    };

    return widget;
}

export function* getDefaultNewsWidget() {
    const widget: NewsWidgetModel = {
        type: 'news',
        channelId: null,
        ideas: [],
        universeId: null,
        symbolFilter: null,
    };

    return widget;
}

export function* getDefaultEventsWidget() {
    const widget: EventsWidgetModel = {
        type: 'events',
        channelId: null,
        universeId: null,
        symbolFilter: '',
    };

    return widget;
}

export function* getDefaultFilingsWidget() {
    // Add this function
    const widget: FilingsWidgetModel = {
        type: 'filings',
        channelId: null,
        universeId: null,
        symbolFilter: null,
        filingTypes: null,
    };

    return widget;
}
