/**
 * Defines the types and interfaces related to locate orders.
 * @module contracts/locates/orders
 */
import type { Instant } from '@js-joda/core';

/**
 * Enum representing the possible status values for a locate order. WIP
 */
export enum LocateOrderStatus {
    /** The order has been optimistically generated and sent to the backend. */
    sent = 'sent',
    /** The order is pending and has not yet been processed. */
    pending = 'pending',
    /** The order is pending and will continue to be processed until cancelled. */
    pendingContinuous = 'pendingContinuous',
    /** The order has failed and cannot be fulfilled. */
    failed = 'failed',
    /** The order has been cancelled by the requester. */
    cancelled = 'cancelled',
    /** The order has been created and is awaiting processing. */
    created = 'created',
    /** The order has been provided to the requester. */
    provided = 'provided',
    /** The order has been quoted, and will not become provided */
    quoted = 'quoted',
    /** The order has been accepted by the provider. */
    accepted = 'accepted',
    /** The order has been rejected by the provider. */
    rejected = 'rejected',
}

/**
 * Represents the known inventory for a locate order.
 */
export interface KnownLocateInventory {
    /**
     * The total quantity of the inventory.
     */
    total: number | null;
    /**
     * The quantity of the inventory for each vendor.
     */
    [vendor: string]: number | null;
}

/**
 * Represents the auto-accept information for a locate order.
 */
export type AutoAcceptInfo =
    | {
          /**
           * Whether auto-accept is enabled or not.
           */
          enabled: false;
          /**
           * The price of the order.
           */
          price?: number;
      }
    | {
          /**
           * Whether auto-accept is enabled or not.
           */
          enabled: true;
          /**
           * The price of the order.
           */
          price: number;
      };

/**
 * Represents a locate order.
 */
export interface LocateOrder {
    /**
     * The ID of the order.
     */
    id: string;
    /**
     * The UI-generated optimistic ID of the order.
     */
    optimisticOrderId?: string;
    /**
     * The time when the order was created.
     */
    createdTime: Instant;
    /**
     * The time when the order was last updated.
     */
    lastUpdateTime: Instant;
    /**
     * @deprecated Use {@link id} instead.
     */
    orderId: string;
    /**
     * The ID of the offer.
     */
    offerId?: string;
    /**
     * The symbol for the security being locates
     */
    symbol: string;
    /**
     * The quantity of the order.
     */
    quantity: number;
    /**
     * The price of the order.
     */
    price?: number;
    /**
     * The UUID of the account linked to this order. Account linking can be changed within an MPID
     */
    accountId?: string;
    /**
     * The name of the provider requested during order creation.
     */
    requestedProviderId?: string;
    /**
     * The ID of the provider assigned to the order. May be same or different from {@link requestedProviderId}.
     */
    resolvedProviderId?: string;
    /**
     * The status of the order.
     */
    orderStatus: LocateOrderStatus;
    /**
     * The reason for rejecting the order.
     */
    rejectReason?: string;
    /**
     * The status of borrowing.
     */
    borrowStatus?: string;
    /**
     * The known inventory for the order.
     */
    inventory?: KnownLocateInventory;
    /**
     * The auto-accept information for the order.
     */
    autoAccept?: AutoAcceptInfo;
    /**
     * The ID of the user whom requested the locate - used for administrative purposes
     */
    requestingUserId?: string;
    /**
     * Tracking order ID used by the client
     */
    clientTrackingId?: string;
}

/**
 * Represents a new locate order.
 */
export interface NewLocateOrder {
    /**
     * The UI-generated ID of the order.
     */
    optimisticOrderId?: string;
    /**
     * The symbol of the order.
     */
    symbol: string;
    /**
     * The quantity of the order.
     */
    quantity: number;
    /**
     * The UUID of the account.
     */
    accountId: string;
    /**
     * The ID of the provider.
     */
    providerId: string;
}

/**
 * Represents the request for locating an order by ID.
 * This is a data requirement for convenience, the endpoint is GET.
 */
export interface LocateOrderByIdRequest {
    /**
     * The UUID of the order.
     */
    orderId: string;
}

/**
 * Represents the response returned when locating an order by ID.
 */
export interface LocateOrderByIdResponse {
    /**
     * The order.
     */
    order: LocateOrder;
}

/**
 * Represents the filters for a list of locate orders.
 */
export interface ListLocateOrdersRequest {
    /**
     * The filters for the list of locate orders.
     */
    filters: {
        /**
         * The IDs of the accounts.
         */
        accountIds?: string[];
    };
}

/**
 * Represents the response for a list of locate orders.
 */
export interface ListLocateOrdersResponse {
    /**
     * The list of locate orders.
     */
    orders: LocateOrder[];
}

/**
 * Represents the response for a new locate order.
 */
export interface NewLocateOrderResponse {
    /**
     * The new locate order.
     */
    orders: LocateOrder[];
}

/**
 * Represents the request for a new locate order.
 */
export interface NewLocateOrderRequest {
    /**
     * The new locate order.
     */
    order: NewLocateOrder;
    // TODO: remove lift everything by symbol & quantity out of NewLocateOrder
    /**
     * Auto-accept configuration
     */
    autoAccept?: AutoAcceptInfo;
}

/**
 * Represents the response for a list of locate orders for a user.
 */
export interface LocateOrdersForUserResponse {
    /**
     * The list of locate orders for the user.
     */
    orders: LocateOrder[];
}

/**
 * Represents the fingerprint data for a legacy locate order, since
 * just using an ID is too much to ask.
 */
export interface LocateLegacyOrderFingerprint {
    /**
     * The price of the order.
     */
    price: number;
    /**
     * The name of the provider.
     */
    provider: string;
    /**
     * The quantity of the order.
     */
    quantity: number;
    /**
     * The symbol of the order.
     */
    symbol: string;
}

/**
 * Represents the accept response for a legacy locate order.
 */
export interface LocateAcceptResponse {
    /**
     * The locate order.
     */
    order: LocateOrder;
}

/**
 * Represents the reject response for a legacy locate order.
 */
export interface LocateRejectResponse {
    /**
     * The locate order.
     */
    order: LocateOrder;
}

export interface LocateCancelResponse {
    /**
     * The locate order.
     */
    order: LocateOrder;
}

export interface LocateLimitRequest {
    autoAccept?: AutoAcceptInfo;
}

export interface LocateLimitResponse {
    /**
     * Updated order
     */
    order: LocateOrder;
}

export interface ClearLocateOrdersRequest {
    accountIds?: string[];
}
export interface ClearLocateOrdersResponse {
    orders: LocateOrder[];
}
